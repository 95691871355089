import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsList from "@/components/CardsList";

import {PlayerAPI} from "@/api/player";

import AddIcon from "@mui/icons-material/Add";

import {ERROR_OPERATION, playerRoles} from "@/utils/constants";
import {useCategories} from "@/utils/hooks";

import "./index.scss";

function PlayersPage(props) {
	const {handleNotification} = props;
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [playerType, setPlayerType] = useState("internal");
	const [players, setPlayers] = useState([]);
	const [filtersData, setFiltersData] = useState(
		JSON.parse(sessionStorage.getItem("all_player_filters")) || {},
	);
	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
		onlyYouth: true,
		formatted: true,
	});
	const [loadedCategories, setLoadedCategories] = useState(false);

	const filters = [
		{
			key: "categoryId",
			type: "select",
			options: categories,
			sorted: false,
			label: "Categoria",
			customClassName: "col-12 col-sm-6 col-lg-4",
			clearable: false,
			value: filtersData?.categoryId,
			setValue: (value) => {
				sessionStorage.setItem(
					"all_player_filters",
					JSON.stringify({categoryId: value}),
				);
				setFiltersData({...filtersData, categoryId: value});
			},
		},
	];

	const getPlayers = async () => {
		if (!filtersData) {
			setLoading(true);
		}
		try {
			const response = await PlayerAPI.list({
				...filtersData,
				categoryId: filtersData?.categoryId?.value,
			});
			setPlayers(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (categories?.length && !loadedCategories && !filtersData?.categoryId) {
			setFiltersData({
				...filtersData,
				categoryId: categories[0],
			});
			setLoadedCategories(true);
		}
	}, [categories]);

	useEffect(() => {
		getPlayers();
	}, [filtersData]);

	// filtro per categoria
	const filteredPlayers = players?.filter(
		(p) => p.category === filtersData?.categoryId?.label,
	);

	const groupedPlayers = {};
	playerRoles.forEach((pr) => {
		const roleLabel = pr.label;
		const roleId = pr.value;
		const playersMembers = filteredPlayers?.filter(
			(p) =>
				((p.goalkeeper && roleId === "Portiere") || p.primaryRole === roleId) &&
				((playerType === "internal" && p.status === "Interno") ||
					(playerType === "loan" && p.status === "Prestito") ||
					(playerType === "external" && p.status === "Esterno")),
		);
		groupedPlayers[roleLabel] = playersMembers;
	});

	const playersListConfiguration = {
		data: groupedPlayers,
		onClick: (player) => navigate("/players/" + player.id),
		imageUrl: (player) =>
			player.goalkeeper
				? require("@/assets/images/manager/player.png")
				: require("@/assets/images/manager/movement_player.png"),
		title: (player) => player.firstName + " " + player.lastName,
		subtitle: (player) =>
			player.dateOfBirth ? moment(player.dateOfBirth).format("YYYY") : "---",
		separator: false,
		// customClassName: (item) => (item.status === "Prestito" ? "loan" : ""),
	};

	const mainActions = [
		{
			icon: <AddIcon />,
			title: "Aggiungi giocatore",
			onClick: () => navigate("/players/new"),
		},
	];

	const listElements = [
		{
			label: "Rosa attuale",
			value: "internal",
			onClick: () => setPlayerType("internal"),
		},
		{
			label: "In prestito",
			value: "loan",
			onClick: () => setPlayerType("loan"),
		},
		{
			label: "Ceduti",
			value: "external",
			onClick: () => setPlayerType("external"),
		},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				currentListElement={playerType}
				listElements={listElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<CardsList
				breadcrumbs
				grouped
				filtersData={filters}
				forceOpened
				type="players"
				listConfiguration={playersListConfiguration}
				defaultGroupSortedOrder="descend"
				defaultSortedField="lastName"
			/>
		</Page>
	);
}

export default PlayersPage;
