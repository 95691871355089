import moment from "moment";

import api from "./configuration";
import apiUrl from "./url";

const USE_AUTH_INTERCEPTOR = true;

export const StatAPI = {
	weeklyRPE: async (params) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/WeeklyRpes",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params,
		});

		return response.data;
	},
	monthlyRPE: async (params) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/MonthlyRpes",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params,
		});

		return response.data;
	},
	weeklyTQR: async (params) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/WeeklyTqrs",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params,
		});

		return response.data;
	},
	monthlyTQR: async (params) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/MonthlyTqrs",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params,
		});

		return response.data;
	},
	weeklyTrainingAttendance: async (params) => {
		const currentWeek = parseInt(moment().format("w")) - 1;
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/WeeklyTrainingAttendance",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params: {
				yearWeek:
					moment().format("yyyy") + currentWeek < 10
						? "0" + currentWeek
						: currentWeek,
				...params,
			},
		});

		return response.data;
	},
	monthlyMatches: async (params) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/MonthlyMatches",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params,
		});

		return response.data;
	},
	trainingsDetails: async (playerId, params) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/PlayerTrainingsDetail/" + playerId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params,
		});

		return response.data;
	},
	weeklyTrainings: async (playerId, params) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/PlayerWeeklyTrainings/" + playerId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params,
		});

		return response.data;
	},
	matchesDetails: async (playerId, params) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/PlayerMatchesDetail/" + playerId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params,
		});

		return response.data;
	},
};
