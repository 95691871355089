import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";

import {AthleticTestAPI} from "@/api/athletic_test";

import AddIcon from "@mui/icons-material/Add";

import {ERROR_OPERATION, dateFormat} from "@/utils/constants";
import {
	fetchData,
	getCurrentCategory,
	getCurrentPlayer,
} from "@/utils/functions";
import {useCategories, usePlayers} from "@/utils/hooks";

import "./index.scss";

const defaultFiltersData = {
	// fromDate: moment().startOf("isoWeek").format("YYYY-MM-DD"),
	// toDate: moment().endOf("isoWeek").format("YYYY-MM-DD"),
};

function AthleticTestsPage(props) {
	const {handleNotification} = props;

	const navigate = useNavigate();

	const userRole = useSelector((state) => state?.user?.roleName);

	const [loading, setLoading] = useState(false);
	const [tests, setTests] = useState([]);
	const [filtersData, setFiltersData] = useState(
		JSON.parse(sessionStorage.getItem("athletic_tests_filters")) ||
			defaultFiltersData,
	);

	useEffect(() => {
		if (filtersData) {
			sessionStorage.setItem(
				"athletic_tests_filters",
				JSON.stringify(filtersData),
			);
		}
	}, [filtersData]);

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
		onlyYouth: true,
		formatted: true,
	});
	const {players} = usePlayers({
		setLoading: false,
		handleNotification: undefined,
	});

	const filters = [
		{
			key: "categoryId",
			type: "select",
			options: categories,
			sorted: false,
			label: "Categoria",
			customClassName: "col-12 col-sm-4",
			clearable: true,
			value: filtersData?.categoryId,
			setValue: (value) => {
				setFiltersData({...filtersData, categoryId: value});
			},
		},
	];

	const getTests = () => {
		fetchData(
			AthleticTestAPI.list,
			{
				categoryId: filtersData?.categoryId?.value,
			},
			() => setLoading(true),
			setTests,
			() => handleNotification(ERROR_OPERATION, "error"),
			() => setLoading(false),
		);
	};

	useEffect(() => {
		if (categories?.length && players?.length) {
			getTests();
		}
	}, [filtersData, categories, players]);

	const listConfiguration = {
		data: tests.map((t) => {
			t.currentCategory = getCurrentCategory(categories, t);
			t.currentPlayer = getCurrentPlayer(players, t);
			return t;
		}),
		onClick: (test) => navigate("/athletic-tests/" + test.id),
	};

	const columns = [
		{
			label: "Data",
			key: "testDate",
			customRender: (item) => moment(item.testDate).format(dateFormat),
			small: true,
		},
		{
			label: "Categoria",
			key: "playerCategoryId",
			customRender: (item) => getCurrentCategory(categories, item),
			small: true,
			align: "start",
		},
		{
			label: "Portiere",
			key: "playerId",
			customRender: (item) => getCurrentPlayer(players, item),
			align: "start",
		},
		{
			label: "Tipologia",
			key: "testType",
			align: "start",
			small: true,
		},
	];

	const mainActions = [];

	if (userRole !== "Player") {
		mainActions.push({
			icon: <AddIcon />,
			title: "Aggiungi test",
			onClick: () => navigate("/athletic-tests/new"),
		});
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={mainActions} />} />
			<CardsContainer
				tableComponent={
					<CardsTable
						fullWidth
						filtersData={filters}
						configurationColumns={columns}
						data={tests}
						handleClick={(item) => {
							navigate("/athletic-tests/" + item.id);
						}}
						defaultSortedField="testDate"
						defaultSortedOrder="descend"
					/>
				}
				listComponent={
					<CardsList
						type="test"
						breadcrumbs
						filtersData={filters}
						listConfiguration={listConfiguration}
						defaultSortedField="testDate"
						defaultSortedOrder="descend"
					/>
				}
			/>
		</Page>
	);
}

export default AthleticTestsPage;
