import React, {useEffect, useState} from "react";

import VLFModal from "@/components/Modal";
import VLFSelectNew from "@/components/Input/SelectNew";
import VLFTextField from "@/components/Input/TextField";

import {REQUIRED_FIELD} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";
import {usePlayers} from "@/utils/hooks";

const requiredFields = ["player", "minutes"];

function HandleStatModal(props) {
	const {open, initialData, confirm, close, handleNotification} = props;

	const [formData, setFormData] = useState({
		player: null,
	});
	const [errors, setErrors] = useState([]);

	const {players} = usePlayers({setLoading: false, handleNotification});

	useEffect(() => {
		if (initialData && players?.length) {
			const currentPlayer = players?.filter(
				(p) => p.id === initialData.playerId,
			)?.[0];
			initialData.player = currentPlayer
				? {
						value: currentPlayer?.id,
						label:
							currentPlayer?.firstName +
							" " +
							currentPlayer?.lastName +
							" (" +
							currentPlayer?.category +
							")",
					}
				: null;
			setFormData(initialData);
		}
	}, [initialData, players]);

	useEffect(() => {
		if (!open) {
			setFormData({});
		}
	}, [open]);

	return (
		<VLFModal
			maxWidth="lg"
			open={open}
			title={initialData ? "Modifica informazione" : "Aggiungi informazione"}
			close={close}
			confirm={() => {
				const errors = checkRequiredFields(requiredFields, formData);
				setErrors(errors);

				if (!errors.length) {
					confirm(formData, initialData?.id);
				}
			}}
			content={
				<>
					<div className="row">
						<div className="col-12 col-lg-6">
							<VLFSelectNew
								options={players
									.filter(
										(p) =>
											(initialData.attendances?.length &&
												initialData.attendances.filter(
													(a) => a.playerId === p.id,
												)?.length) ||
											!initialData.attendances?.length,
									)
									.map((p) => {
										return {
											value: p.id,
											label:
												p.firstName +
												" " +
												p.lastName +
												" (" +
												p.category +
												")",
										};
									})}
								value={formData.player || ""}
								onChange={(value) => {
									setErrors([]);
									setFormData({...formData, player: value});
								}}
								required
								helperText={
									errors.indexOf("player") !== -1 ? REQUIRED_FIELD : ""
								}
								error={errors.indexOf("player") !== -1}
								label={"Portiere"}
							/>
						</div>
						<div
							className={
								initialData?.hasRPE ? "col-12 col-lg-6" : "col-12 col-lg-3"
							}
						>
							<VLFTextField
								name="minutes"
								value={formData.minutes || ""}
								onChange={(event) => {
									setErrors([]);
									setFormData({...formData, minutes: event.target.value});
								}}
								label="Minuti"
								size="small"
								type="number"
								required
								helperText={
									errors.indexOf("minutes") !== -1 ? REQUIRED_FIELD : ""
								}
								error={errors.indexOf("minutes") !== -1}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-lg-3">
							<VLFTextField
								name="goalsConceded"
								value={
									formData.goalsConceded !== null &&
									formData.goalsConceded !== undefined
										? formData.goalsConceded
										: ""
								}
								onChange={(event) => {
									setFormData({...formData, goalsConceded: event.target.value});
								}}
								label="Gol subiti"
								size="small"
								type="number"
							/>
						</div>
						{initialData?.hasRPE && (
							<div className="col-12 col-lg-3">
								<VLFTextField
									name="saves"
									value={
										formData.saves !== null && formData.saves !== undefined
											? formData.saves
											: ""
									}
									onChange={(event) => {
										setFormData({...formData, saves: event.target.value});
									}}
									label="Parate"
									size="small"
									type="number"
								/>
							</div>
						)}
					</div>
					<div className="row">
						{initialData?.hasRPE && (
							<div className="col-12 col-lg-3">
								<VLFTextField
									name="outOfAreaReadings"
									value={
										formData.outOfAreaReadings !== null &&
										formData.outOfAreaReadings !== undefined
											? formData.outOfAreaReadings
											: ""
									}
									onChange={(event) => {
										setFormData({
											...formData,
											outOfAreaReadings: event.target.value,
										});
									}}
									label="Letture fuori area"
									size="small"
									type="number"
								/>
							</div>
						)}
						{initialData?.hasRPE && (
							<div className="col-12 col-lg-3">
								<VLFTextField
									name="lowReadings"
									value={
										formData.lowReadings !== null &&
										formData.lowReadings !== undefined
											? formData.lowReadings
											: ""
									}
									onChange={(event) => {
										setFormData({...formData, lowReadings: event.target.value});
									}}
									label="Letture area basse"
									size="small"
									type="number"
								/>
							</div>
						)}
						{initialData?.hasRPE && (
							<div className="col-12 col-lg-3">
								<VLFTextField
									name="highReadings"
									value={
										formData.highReadings !== null &&
										formData.highReadings !== undefined
											? formData.highReadings
											: ""
									}
									onChange={(event) => {
										setFormData({
											...formData,
											highReadings: event.target.value,
										});
									}}
									label="Letture area alte"
									size="small"
									type="number"
								/>
							</div>
						)}
					</div>
					<div className="row">
						{initialData?.hasRPE && (
							<div className="col-12 col-lg-3">
								<VLFTextField
									name="shortHorizontalPassRight"
									value={
										formData.shortHorizontalPassRight !== null &&
										formData.shortHorizontalPassRight !== undefined
											? formData.shortHorizontalPassRight
											: ""
									}
									onChange={(event) => {
										setFormData({
											...formData,
											shortHorizontalPassRight: event.target.value,
										});
									}}
									label="Pass. corti orizz. dx"
									size="small"
									type="number"
								/>
							</div>
						)}
						{initialData?.hasRPE && (
							<div className="col-12 col-lg-3">
								<VLFTextField
									name="shortHorizontalPassLeft"
									value={
										formData.shortHorizontalPassLeft !== null &&
										formData.shortHorizontalPassLeft !== undefined
											? formData.shortHorizontalPassLeft
											: ""
									}
									onChange={(event) => {
										setFormData({
											...formData,
											shortHorizontalPassLeft: event.target.value,
										});
									}}
									label="Pass. corti orizz. sx"
									size="small"
									type="number"
								/>
							</div>
						)}
						{initialData?.hasRPE && (
							<div className="col-12 col-lg-3">
								<VLFTextField
									name="shortVerticalPass"
									value={
										formData.shortVerticalPass !== null &&
										formData.shortVerticalPass !== undefined
											? formData.shortVerticalPass
											: ""
									}
									onChange={(event) => {
										setFormData({
											...formData,
											shortVerticalPass: event.target.value,
										});
									}}
									label="Pass. corti verticale"
									size="small"
									type="number"
								/>
							</div>
						)}
					</div>
					<div className="row">
						{initialData?.hasRPE && (
							<div className="col-12 col-lg-3">
								<VLFTextField
									name="killerPass"
									value={
										formData.killerPass !== null &&
										formData.killerPass !== undefined
											? formData.killerPass
											: ""
									}
									onChange={(event) => {
										setFormData({...formData, killerPass: event.target.value});
									}}
									label="Killer pass"
									size="small"
									type="number"
								/>
							</div>
						)}
					</div>
					<div className="row">
						{initialData?.hasRPE && (
							<div className="col-12 col-lg-3">
								<VLFTextField
									name="longFrontalPass"
									value={
										formData.longFrontalPass !== null &&
										formData.longFrontalPass !== undefined
											? formData.longFrontalPass
											: ""
									}
									onChange={(event) => {
										setFormData({
											...formData,
											longFrontalPass: event.target.value,
										});
									}}
									label="Passaggi lunghi frontale"
									size="small"
									type="number"
								/>
							</div>
						)}
						{initialData?.hasRPE && (
							<div className="col-12 col-lg-3">
								<VLFTextField
									name="longPassRight"
									value={
										formData.longPassRight !== null &&
										formData.longPassRight !== undefined
											? formData.longPassRight
											: ""
									}
									onChange={(event) => {
										setFormData({
											...formData,
											longPassRight: event.target.value,
										});
									}}
									label="Passaggi lunghi dx"
									size="small"
									type="number"
								/>
							</div>
						)}
						{initialData?.hasRPE && (
							<div className="col-12 col-lg-3">
								<VLFTextField
									name="longPassLeft"
									value={
										formData.longPassLeft !== null &&
										formData.longPassLeft !== undefined
											? formData.longPassLeft
											: ""
									}
									onChange={(event) => {
										setFormData({
											...formData,
											longPassLeft: event.target.value,
										});
									}}
									label="Passaggi lunghi sx"
									size="small"
									type="number"
								/>
							</div>
						)}
					</div>
				</>
			}
		/>
	);
}

export default HandleStatModal;
