import React, {useEffect, useState} from "react";
import moment from "moment";

import VLFModal from "@/components/Modal";
import VLFTextField from "@/components/Input/TextField";
import VLFSelectNew from "@/components/Input/SelectNew";
import CardsList from "@/components/CardsList";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import {ProspectAPI} from "@/api/prospect";

import {REQUIRED_FIELD} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";
import {useCategories, usePlayers} from "@/utils/hooks";

import "./index.scss";

const requiredFields = ["name", "category"];

const birthYears = [];
for (let i = 2006; i <= 2018; i++) {
	birthYears.push({value: i, label: i.toString()});
}

function HandleRosterModal(props) {
	const {open, initialData, confirm, close} = props;

	const [formData, setFormData] = useState({
		players: [],
	});
	const [playersConfiguration, setPlayersConfiguration] = useState();
	const [errors, setErrors] = useState([]);
	const [loading, setLoading] = useState(false);

	const [searched, setSearched] = useState("");
	const [birthYear, setBirthYear] = useState();

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
		formatted: true,
		onlyYouth: true,
	});
	const {players} = usePlayers({
		setLoading: false,
		handleNotification: undefined,
	});
	const [prospectPlayers, setProspectPlayers] = useState([]);
	const getProspectPlayers = async () => {
		setLoading(true);
		try {
			const response = await ProspectAPI.playersList();
			setProspectPlayers(response);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getProspectPlayers();
	}, []);

	useEffect(() => {
		// in edit, se ci sono nei dati iniziale dei giocatori verifico se siano interni o prospect
		// la categoria viene letta dal primo dei giocatori iniziali
		if (initialData) {
			const initialPlayers = initialData?.players || [];
			const selectedPlayers = initialPlayers.map((p) => {
				const isInternalPlayer = p.membershipDate !== undefined;
				return {
					id: isInternalPlayer ? p.playerId : p.prospectPlayerId,
					internal: isInternalPlayer,
				};
			});

			const selectedCategory = {
				value: initialPlayers?.[0]?.playersCategory.id,
				label: initialPlayers?.[0]?.playersCategory.name,
			};

			setFormData({
				...initialData,
				category: selectedCategory,
				players: selectedPlayers,
			});
		}
	}, [initialData]);

	useEffect(() => {
		if (!open) {
			setFormData({players: []});
		}
	}, [open]);

	const allPlayers = players?.concat?.(prospectPlayers);
	let filteredPlayers = searched
		? allPlayers?.filter((p) => {
				return (
					p.firstName.toLowerCase()?.indexOf(searched.toLowerCase()) !== -1 ||
					p.lastName.toLowerCase()?.indexOf(searched.toLowerCase()) !== -1
				);
			})
		: allPlayers;
	filteredPlayers = birthYear
		? filteredPlayers.filter((p) => {
				return (
					p.dateOfBirth &&
					moment(p.dateOfBirth).format("YYYY") === birthYear?.label
				);
			})
		: filteredPlayers;

	// ordinamento dei giocatori per cognome, mettendo per primi quelli selezionati
	const sortArray = (array1, array2) => {
		// creare una mappa per gli ID presenti nel secondo array
		const array2Ids = new Set(array2.map((item) => item.id));

		return array1.sort((a, b) => {
			const isInArray2A = array2Ids.has(a.id);
			const isInArray2B = array2Ids.has(b.id);

			// se entrambi sono in array2 o entrambi non lo sono, ordina per lastName
			if (isInArray2A === isInArray2B) {
				return a.lastName.localeCompare(b.lastName);
			}

			// altrimenti, metti prima gli elementi di array2
			return isInArray2A ? -1 : 1;
		});
	};

	useEffect(() => {
		setPlayersConfiguration({
			data: sortArray(filteredPlayers, formData?.players),
			onClick: (element) => {
				let updatedPlayers = Array.from(formData.players || []);
				// controllo se il giocatore sia già selezionato oppure no
				if (
					!formData?.players ||
					formData?.players?.filter((p) => p.id === element.id)?.length === 0
				) {
					if (!formData?.players || formData?.players?.length < 4) {
						const isInternalPlayer = element.membershipDate !== undefined;
						updatedPlayers.push({
							id: element.id,
							internal: isInternalPlayer,
						});
						setFormData({...formData, players: updatedPlayers});
					}
				} else {
					const index = formData.players.findIndex((p) => p.id === element.id);
					updatedPlayers.splice(index, 1);
					if (index > -1) {
						setFormData({
							...formData,
							players: updatedPlayers,
						});
					}
				}
			},
			selected: (element) =>
				formData?.players &&
				formData.players.filter((p) => p.id === element.id)?.length,
			internal: (element) => element.membershipDate !== undefined,
			separator: false,
		});
	}, [searched, birthYear, formData, players, prospectPlayers]);

	const selectedPlayersConfiguration = allPlayers
		.filter(
			(p) =>
				formData?.players &&
				formData.players.filter((p2) => p2.id === p.id)?.length,
		)
		?.sort((a, b) => {
			return a.lastName.localeCompare(b.lastName);
		})
		?.map((p) => p.firstName + " " + p.lastName);

	return (
		<VLFModal
			maxWidth="md"
			open={open}
			title={initialData ? "Modifica roster" : "Aggiungi roster"}
			close={close}
			confirm={() => {
				const errors = checkRequiredFields(requiredFields, formData);
				setErrors(errors);

				if (formData?.players?.length === 0) {
					setErrors([...errors, "players"]);
				} else {
					if (!errors?.length) {
						confirm(formData, initialData?.id);
					}
				}
			}}
			content={
				<>
					<div className="row">
						<div className="col-12 col-sm-6">
							<VLFTextField
								name="name"
								value={formData?.name || ""}
								onChange={(event) => {
									setErrors([]);
									setFormData({...formData, name: event.target.value});
								}}
								label="Nome"
								required
								size="small"
								helperText={errors.indexOf("name") !== -1 ? REQUIRED_FIELD : ""}
								error={errors.indexOf("name") !== -1}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<VLFSelectNew
								options={categories}
								sortOrder="descend"
								value={formData?.category}
								onChange={(newValue) => {
									setErrors([]);
									setFormData({...formData, category: newValue});
								}}
								label="Categoria"
								required
								size="small"
								helperText={
									errors.indexOf("category") !== -1 ? REQUIRED_FIELD : ""
								}
								error={errors.indexOf("category") !== -1}
							/>
						</div>
					</div>
					<div style={{fontWeight: "bold"}}>Portieri selezionati</div>
					<div className="mb-2">
						{selectedPlayersConfiguration?.length
							? selectedPlayersConfiguration.join(" - ")
							: "---"}
					</div>
					<div className="f-bold">Seleziona portieri</div>
					<div className="players-filters-container">
						<div className="players-filter">
							<VLFTextField
								name="searched"
								value={searched || ""}
								onChange={(event) => {
									setSearched(event.target.value);
								}}
								placeholder="Cerca..."
								size="small"
							/>
						</div>
						<div className="players-filter">
							<VLFSelectNew
								options={birthYears}
								clearable
								value={birthYear}
								onChange={(newValue) => {
									setBirthYear(newValue);
								}}
								placeholder="Anno di nascita"
							/>
						</div>
					</div>
					<div className="players-list-container">
						{loading ? (
							<Box sx={{display: "flex", justifyContent: "center"}}>
								<CircularProgress />
							</Box>
						) : (
							<CardsList
								type="roster_player"
								listConfiguration={playersConfiguration}
							/>
						)}
					</div>
				</>
			}
		/>
	);
}

export default HandleRosterModal;
