import React, {useEffect, useState} from "react";
import moment from "moment";

import VLFModal from "@/components/Modal";
import VLFDatePicker from "@/components/Input/DatePicker";
import VLFSelectNew from "@/components/Input/SelectNew";
import VLFTextField from "@/components/Input/TextField";
import SwitchView from "@/components/SwitchView";

import {ProspectAPI} from "@/api/prospect";

import {dateFormat, REQUIRED_FIELD} from "@/utils/constants";
import {checkRequiredFields, getTextField} from "@/utils/functions";
import {usePlayers} from "@/utils/hooks";

function HandleNoteModal(props) {
	const {type, external = false, open, initialData, confirm, close} = props;

	// type
	// player => dettaglio giocatore
	// match => dettaglio match
	// player_match => dettaglio giocatore/match

	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState([]);

	const [currentSwitchView, setCurrentSwitchView] = useState(
		external ? "external" : "internal",
	);
	const [externalPlayerView, setExternalPlayerView] = useState(
		initialData ? "select" : "new",
	);

	const {players} = usePlayers({
		setLoading: false,
		handleNotification: undefined,
	});
	const [prospectPlayers, setProspectPlayers] = useState([]);
	const getProspectPlayers = async () => {
		try {
			const response = await ProspectAPI.playersList();
			setProspectPlayers(
				response.map((p) => {
					return {
						value: p.id,
						label:
							p.firstName +
							" " +
							p.lastName +
							" (" +
							(p.dateOfBirth
								? moment(p.dateOfBirth).format(dateFormat)
								: "---") +
							")",
					};
				}),
			);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		if (type !== "player") {
			getProspectPlayers();
		}
	}, [type]);

	const createProspectPlayer = async () => {
		try {
			const body = {
				firstName: formData?.firstName ? formData.firstName : "",
				lastName: formData?.lastName,
				dateOfBirth: formData?.dateOfBirth
					? moment(formData?.dateOfBirth).format("YYYY-MM-DD")
					: undefined,
			};

			const response = await ProspectAPI.createPlayer(body);
			return response;
		} catch (error) {
			console.log(error);
		}
	};

	const getRequiredFields = () => {
		if (type === "player") {
			return ["note"];
		} else {
			if (
				currentSwitchView === "internal" ||
				(currentSwitchView === "external" && externalPlayerView === "select")
			) {
				return ["notePlayer", "note"];
			} else {
				return ["lastName", "note"];
			}
		}
	};

	useEffect(() => {
		if (
			type !== "player" &&
			initialData &&
			(players?.length || prospectPlayers?.length)
		) {
			if (initialData.prospectPlayerId) {
				setCurrentSwitchView("external");
			}
			const key =
				currentSwitchView === "external" ? "prospectPlayerId" : "playerId";
			const collection =
				currentSwitchView === "external" ? prospectPlayers : players;
			const playerKey = currentSwitchView === "external" ? "value" : "id";

			if (initialData[key]) {
				const currentPlayer =
					collection?.filter((p) => p[playerKey] === initialData[key])?.[0] ||
					{};

				initialData.notePlayer = {
					value: initialData[key],
					label:
						currentSwitchView === "external"
							? currentPlayer.label
							: currentPlayer.firstName +
								" " +
								currentPlayer.lastName +
								" (" +
								currentPlayer.category +
								")",
				};
			}
			setFormData(initialData);
		}
	}, [type, initialData, players, prospectPlayers]);

	useEffect(() => {
		if (!open) {
			setFormData({});
		}
	}, [open]);

	const defaultTextField = {
		values: formData,
		setValues: setFormData,
		errors,
		setErrors,
	};

	return (
		<VLFModal
			maxWidth="md"
			open={open}
			title={initialData ? "Modifica informazione" : "Aggiungi informazione"}
			close={close}
			confirm={async () => {
				const errors = checkRequiredFields(getRequiredFields(), formData);
				setErrors(errors);

				if (!errors.length) {
					let playerData = {};

					if (type !== "player") {
						if (currentSwitchView === "external") {
							if (externalPlayerView === "new") {
								const playerResponse = await createProspectPlayer();
								playerData = {prospectPlayerId: playerResponse?.id};
							} else {
								playerData = {prospectPlayerId: formData?.notePlayer?.value};
							}
						} else {
							playerData = {playerId: formData?.notePlayer?.value};
						}
					}
					confirm({...formData, ...playerData}, initialData?.id);
				}
			}}
			content={
				<div className="row">
					{type !== "player" && (
						<>
							<div className="col-xs-12">
								<div style={{fontWeight: "bold", marginBottom: "5px"}}>
									Tipologia portiere
								</div>
								<div className="d-flex align-items-center gap-2">
									{!external ? (
										<SwitchView
											currentSwitchView={currentSwitchView}
											setCurrentSwitchView={setCurrentSwitchView}
											pageOptions={[
												{value: "internal", label: "Interno"},
												{value: "external", label: "Esterno"},
											]}
											fixed
											callback={() => {
												setFormData({
													...formData,
													firstName: "",
													lastName: "",
													dateOfBirth: null,
													notePlayer: null,
												});
												setExternalPlayerView("new");
											}}
										/>
									) : (
										""
									)}
									{currentSwitchView === "external" ? (
										<SwitchView
											currentSwitchView={externalPlayerView}
											setCurrentSwitchView={setExternalPlayerView}
											pageOptions={[
												{value: "new", label: "Nuovo"},
												{value: "select", label: "Esistente"},
											]}
											fixed
											callback={() => {
												setFormData({
													...formData,
													firstName: "",
													lastName: "",
													dateOfBirth: null,
													notePlayer: null,
												});
											}}
										/>
									) : null}
								</div>
							</div>
							<div className="col-xs-12">
								{currentSwitchView === "internal" ||
								(currentSwitchView === "external" &&
									externalPlayerView === "select") ? (
									<VLFSelectNew
										options={
											currentSwitchView === "internal"
												? players.map((p) => {
														return {
															value: p.id,
															label:
																p.firstName +
																" " +
																p.lastName +
																" (" +
																p.category +
																")",
														};
													})
												: prospectPlayers
										}
										value={formData.notePlayer || ""}
										onChange={(value) => {
											setErrors([]);
											setFormData({...formData, notePlayer: value});
										}}
										required
										helperText={
											errors.indexOf("notePlayer") !== -1 ? REQUIRED_FIELD : ""
										}
										error={errors.indexOf("notePlayer") !== -1}
										label={"Portiere"}
									/>
								) : (
									<div className="row">
										<div className="col-12 col-sm-6 col-xl-4">
											{getTextField({
												...defaultTextField,
												fieldKey: "firstName",
												label: "Nome",
											})}
										</div>
										<div className="col-12 col-sm-6 col-xl-4">
											{getTextField({
												...defaultTextField,
												fieldKey: "lastName",
												label: "Cognome",
												required: true,
											})}
										</div>
										<div className="col-12 col-sm-6 col-xl-4">
											<VLFDatePicker
												label="Data di nascita"
												value={formData.dateOfBirth}
												onChange={(value) => {
													setFormData({...formData, dateOfBirth: value});
												}}
												format="DD-MM-YYYY"
											/>
										</div>
									</div>
								)}
							</div>
						</>
					)}
					<div className="col-xs-12">
						<VLFDatePicker
							label="Data"
							value={formData.date}
							onChange={(value) => {
								setErrors([]);
								setFormData({...formData, date: value});
							}}
							format="DD-MM-YYYY"
							size="small"
						/>
					</div>
					{type !== "match" && (
						<div className="col-xs-12">
							<VLFTextField
								name="title"
								value={formData.title || ""}
								onChange={(event) => {
									setFormData({...formData, title: event.target.value});
								}}
								label="Titolo"
								size="small"
							/>
						</div>
					)}
					<div className="col-xs-12">
						<VLFTextField
							value={formData.note || ""}
							onChange={(event) => {
								setErrors([]);
								setFormData({...formData, note: event.target.value});
							}}
							label="Nota"
							required
							size="small"
							helperText={errors.indexOf("note") !== -1 ? REQUIRED_FIELD : ""}
							error={errors.indexOf("note") !== -1}
							multiline
							minRows={3}
							maxRows={5}
						/>
					</div>
					{type === "match" && (
						<div className="col-xs-12">
							<VLFTextField
								name="title"
								value={formData.title || ""}
								onChange={(event) => {
									setFormData({...formData, title: event.target.value});
								}}
								label="Autore"
								size="small"
							/>
						</div>
					)}
				</div>
			}
		/>
	);
}

export default HandleNoteModal;
