import React, {useEffect, useState} from "react";

import VLFModal from "@/components/Modal";
import VLFSelectNew from "@/components/Input/SelectNew";

import {attendanceStatusOptions, REQUIRED_FIELD} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";
import {usePlayers} from "@/utils/hooks";

const requiredFields = ["player", "status"];

function HandleAttendanceModal(props) {
	const {open, confirm, close, initialData} = props;

	const [formData, setFormData] = useState({
		player: null,
	});
	const [errors, setErrors] = useState([]);

	const {players} = usePlayers({
		setLoading: false,
		handleNotification: undefined,
		onlyInternal: true,
	});

	useEffect(() => {
		if (initialData && players?.length) {
			const currentPlayer = players?.filter(
				(p) => p.id === initialData.playerId,
			)?.[0];
			initialData.player = {
				value: currentPlayer?.id,
				label:
					currentPlayer?.firstName +
					" " +
					currentPlayer?.lastName +
					" (" +
					currentPlayer?.category +
					")",
			};
			initialData.status = {
				value: initialData.status,
				label: initialData.status,
			};
			setFormData(initialData);
		}
	}, [initialData, players]);

	useEffect(() => {
		if (!open) {
			setFormData({});
		}
	}, [open]);

	return (
		<VLFModal
			maxWidth="lg"
			open={open}
			title={initialData ? "Modifica informazione" : "Aggiungi informazione"}
			close={close}
			confirm={() => {
				const errors = checkRequiredFields(requiredFields, formData);
				setErrors(errors);

				if (!errors.length) {
					confirm(formData, initialData?.id);
				}
			}}
			content={
				<div className="row">
					<div className="col-12 col-md-6">
						<VLFSelectNew
							options={players.map((p) => {
								return {
									value: p.id,
									label:
										p.firstName + " " + p.lastName + " (" + p.category + ")",
								};
							})}
							value={formData.player || ""}
							onChange={(value) => {
								setErrors([]);
								setFormData({...formData, player: value});
							}}
							required
							helperText={errors.indexOf("player") !== -1 ? REQUIRED_FIELD : ""}
							error={errors.indexOf("player") !== -1}
							label={"Portiere"}
						/>
					</div>
					<div className="col-12 col-md-6">
						<VLFSelectNew
							options={attendanceStatusOptions}
							value={formData.status || ""}
							onChange={(value) => {
								setErrors([]);
								setFormData({...formData, status: value});
							}}
							required
							helperText={errors.indexOf("status") !== -1 ? REQUIRED_FIELD : ""}
							error={errors.indexOf("status") !== -1}
							label={"Status"}
						/>
					</div>
				</div>
			}
		/>
	);
}

export default HandleAttendanceModal;
