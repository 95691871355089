import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";

import {TargetAPI} from "@/api/target";

import AddIcon from "@mui/icons-material/Add";

import {dateFormat} from "@/utils/constants";
import {isDateValid} from "@/utils/functions";

import "./index.scss";

const fieldDateFormat = "YYYY-MM-DD";

function TargetsPage(props) {
	const navigate = useNavigate();

	const userId = useSelector((state) => state?.user?.userId);

	const defaultFiltersData = {
		// fromDate: moment().startOf("month").format(fieldDateFormat),
		toDate: moment().endOf("month").format(fieldDateFormat),
	};

	const [loading, setLoading] = useState(false);

	const [targets, setTargets] = useState([]);
	const [filtersData, setFiltersData] = useState(
		JSON.parse(sessionStorage.getItem("targets_filters")) || defaultFiltersData,
	);

	useEffect(() => {
		if (filtersData) {
			sessionStorage.setItem("targets_filters", JSON.stringify(filtersData));
		}
	}, [filtersData]);

	const filters = [
		{
			key: "fromDate",
			type: "date",
			label: "Da",
			customClassName: "col-6 col-sm-4",
			clearable: true,
			value: filtersData?.fromDate
				? moment(filtersData.fromDate).format(fieldDateFormat)
				: "",
			setValue: (e) => {
				const filters = {
					...filtersData,
					fromDate: e ? moment(e).format(fieldDateFormat) : undefined,
				};
				setFiltersData(filters);
			},
		},
		{
			key: "toDate",
			type: "date",
			label: "A",
			customClassName: "col-6 col-sm-4",
			clearable: false,
			value: moment(filtersData?.toDate).format(fieldDateFormat),
			setValue: (e) => {
				if (e) {
					const filters = {
						...filtersData,
						toDate: moment(e).format(fieldDateFormat),
					};
					setFiltersData(filters);
				}
			},
		},
	];

	const getTargets = async () => {
		setLoading(true);
		try {
			const response = await TargetAPI.list({...filtersData, userId});
			setTargets(response);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getTargets();
	}, [filtersData]);

	const columns = [
		{
			label: "Data",
			key: "targetDate",
			customRender: (item) => {
				return moment(item.targetDate).format(dateFormat);
			},
			medium: true,
		},
		{
			label: "Categoria",
			key: "playersCategory.name",
			align: "start",
			medium: true,
		},
		{
			label: "Scadenza",
			key: "targetDeadline",
			customRender: (item) => {
				return isDateValid(item.targetDeadline)
					? moment(item.targetDeadline).format(dateFormat)
					: "---";
			},
			medium: true,
		},
		{
			label: "Descrizione",
			key: "description",
			align: "start",
		},
		{
			label: "Data raggiungimento",
			key: "dateReached",
			customRender: (item) => {
				return isDateValid(item.dateReached)
					? moment(item.dateReached).format(dateFormat)
					: "---";
			},
			medium: true,
		},
		{
			label: "Raggiunto",
			key: "reached",
			customRender: (item) => {
				return <div className={"cell" + (item.reached ? " on" : " off")}></div>;
			},
			align: "center",
			small: true,
		},
	];

	const mainActions = [
		{
			icon: <AddIcon />,
			onClick: () => navigate("/manager/targets/new"),
		},
	];

	const moveActions = [
		// {
		// 	variant: "outlined",
		// 	label: "<< mese",
		// 	onClick: () => {
		// 		setFiltersData({
		// 			...filtersData,
		// 			fromDate: moment(filtersData?.fromDate)
		// 				.subtract(1, "months")
		// 				.startOf("month")
		// 				.format(fieldDateFormat),
		// 			toDate: moment(filtersData?.toDate)
		// 				.subtract(1, "months")
		// 				.endOf("month")
		// 				.format(fieldDateFormat),
		// 		});
		// 	},
		// },
		// {
		// 	variant: "outlined",
		// 	label: "mese >>",
		// 	onClick: () => {
		// 		setFiltersData({
		// 			...filtersData,
		// 			fromDate: moment(filtersData?.fromDate)
		// 				.add(1, "months")
		// 				.format(fieldDateFormat),
		// 			toDate: moment(filtersData?.toDate)
		// 				.add(1, "months")
		// 				.format(fieldDateFormat),
		// 		});
		// 	},
		// },
	];

	const listConfiguration = {
		data: targets,
		onClick: (item) => {
			navigate("/manager/targets/" + item.id);
		},
		customClassName: (item) =>
			isDateValid(item.targetDeadline) &&
			moment(item.targetDeadline).isBefore(moment())
				? "warning"
				: "",
	};

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				actions={<MainActions actions={[...moveActions, ...mainActions]} />}
			/>
			<CardsContainer
				tableComponent={
					<CardsTable
						filtersData={filters}
						configurationColumns={columns}
						data={targets}
						fullWidth
						handleClick={(item) => {
							navigate("/manager/targets/" + item.id);
						}}
						rowClassName={(item) =>
							isDateValid(item.targetDeadline) &&
							moment(item.targetDeadline).isBefore(moment())
								? "warning"
								: ""
						}
						defaultSortedField="targetDate"
						defaultSortedOrder="descend"
					/>
				}
				listComponent={
					<CardsList
						type="target"
						breadcrumbs
						filtersData={filters}
						listConfiguration={listConfiguration}
						defaultSortedField="targetDate"
						defaultSortedOrder="descend"
					/>
				}
			/>
		</Page>
	);
}

export default TargetsPage;
