import * as React from "react";
import moment from "moment";

import {dateFormat} from "@/utils/constants";

import "./index.scss";

export default function AthleticTestCard(props) {
	const {element, base} = props;

	return (
		<div className="test-card-container">
			{!base && (
				<div className="test-card-header">
					<div className="top-left">
						{moment(element.testDate).format(dateFormat)}
					</div>
					<div className="top-right">{element.currentCategory}</div>
				</div>
			)}
			<div className="test-card-info">
				{base && (
					<div className="top-left">
						{moment(element.testDate).format(dateFormat)}
					</div>
				)}
				<img
					src={
						element.testType === "Spinte"
							? require("@/assets/images/coach/jumping.png")
							: require("@/assets/images/coach/running.png")
					}
					alt=""
				/>
				{!base && <div className="info-title">{element.currentPlayer}</div>}
				{!base && (
					<div className="info-subtitle">{element.testType || "---"}</div>
				)}
			</div>
		</div>
	);
}
