export const headerMenuItems = [
	{
		value: "manager",
		image: require("@/assets/images/manager_white.png"),
		label: "Manager",
		roles: ["admin"],
	},
	{
		value: "coach",
		image: require("@/assets/images/coach_white.png"),
		label: "Allenatore",
		roles: ["coach"],
	},
	{
		value: "analyst",
		image: require("@/assets/images/coach_white.png"),
		label: "Match Analyst",
		roles: ["analyst"],
	},
	{
		value: "player",
		image: require("@/assets/images/player_white.png"),
		label: "Portiere",
		roles: ["player"],
	},
	{
		value: "scout",
		image: require("@/assets/images/scout_white.png"),
		label: "Scout",
		roles: ["scout"],
	},
	{
		value: "settings",
		image: require("@/assets/images/settings_white.png"),
		label: "Configurazioni",
		roles: ["admin"],
	},
];

export const genderOptions = [
	{label: "F", value: "F"},
	{label: "M", value: "M"},
];
export const sideOptions = [
	{label: "Destro", value: "Destro"},
	{label: "Sinistro", value: "Sinistro"},
	{label: "Entrambi", value: "Entrambi"},
];
export const playerRoleOptions = [
	{label: "Portiere", value: "goalkeeper"},
	{label: "Giocatore di movimento", value: "movement_player"},
];
export const categoryYearOptions = [
	{label: "2023/24", value: "2023/24"},
	{label: "2022/23", value: "2022/23"},
];
export const ratingOptions = [
	{label: "Insufficiente", value: "5"},
	{label: "Discreto", value: "6"},
	{label: "Buono", value: "7"},
	{label: "Ottimo", value: "8"},
];

export const prospectPlayerStatus = [
	{label: "Da provinare", value: "reported"},
	{label: "Provinato", value: "valuated"},
	{label: "Scartato", value: "discarted"},
	{label: "Acquisito", value: "buyed"},
];

export const rpeOptions = [
	{value: "1", label: "1 - Molto leggero" /*, color: "#e0f7ff"*/},
	{value: "2", label: "2 - Leggero" /*, color: "#bed3ff"*/},
	{value: "3", label: "3 - Leggero" /*, color: "#bed3ff"*/},
	{value: "4", label: "4 - Moderato" /*, color: "#cbffb6"*/},
	{value: "5", label: "5 - Moderato" /*, color: "#cbffb6"*/},
	{value: "6", label: "6 - Moderato" /*, color: "#cbffb6"*/},
	{value: "7", label: "7 - Pesante" /*, color: "#fbd67d"*/},
	{value: "8", label: "8 - Pesante" /*, color: "#fbd67d"*/},
	{value: "9", label: "9 - Molto pesante" /*, color: "#f88266"*/},
	{value: "10", label: "10 - Estremamente pesante" /*, color: "#ff5559"*/},
];

export const tqrOptions = [
	{value: "6", label: "6 - Nessun recupero" /*, color: "#f88266"*/},
	{value: "7", label: "7 - Nessun recupero" /*, color: "#f88266"*/},
	{
		value: "8",
		label: "8 - Recupero estremamente scarso" /*, color: "#f9acac"*/,
	},
	{
		value: "9",
		label: "9 - Recupero molto scarso" /*, color: "#f7e5a7"*/,
	},
	{
		value: "10",
		label: "10 - Recupero molto scarso" /*, color: "#f7e5a7"*/,
	},
	{value: "11", label: "11 - Recupero scarso" /*, color: "#f7e84f"*/},
	{value: "12", label: "12 - Recupero scarso" /*, color: "#f7e84f"*/},
	{
		value: "13",
		label: "13 - Recupero ragionevole" /*, color: "#a7ffa8"*/,
	},
	{
		value: "14",
		label: "14 - Recupero ragionevole" /*, color: "#a7ffa8"*/,
	},
	{value: "15", label: "15 - Recupero buono" /*, color: "#76cd71"*/},
	{value: "16", label: "16 - Recupero buono" /*, color: "#76cd71"*/},
	{
		value: "17",
		label: "17 - Recupero molto buono" /*, color: "#cefaf8"*/,
	},
	{
		value: "18",
		label: "18 - Recupero molto buono" /*, color: "#cefaf8"*/,
	},
	{value: "19", label: "19 - Recupero massimo" /*, color: "#98c6ff"*/},
	{value: "20", label: "20 - Recupero massimo" /*, color: "#98c6ff"*/},
];

export const vasOptions = [
	{value: "0", label: "0 - Nessun dolore"},
	{value: "1", label: "1 - Dolore molto lieve"},
	{value: "2", label: "2 - Dolore molto lieve"},
	{value: "3", label: "3 - Dolore molto lieve"},
	{value: "4", label: "4 - Dolore lieve"},
	{value: "5", label: "5 - Dolore lieve"},
	{value: "6", label: "6 - Dolore moderato"},
	{value: "7", label: "7 - Dolore moderato"},
	{value: "8", label: "8 - Dolore forte"},
	{value: "9", label: "9 - Dolore forte"},
	{value: "10", label: "10 - Dolore molto forte"},
];

export const medicalRecordTypes = [
	{label: "Infortunio", value: "injury"},
	{label: "Malattia", value: "illness"},
];

export const statusOptions = [
	{value: "Esterno", label: "Esterno"},
	{value: "Interno", label: "Interno"},
	{value: "Prestito", label: "Prestito"},
];

export const playerRoles = [
	{value: "Portiere", label: "Portieri"},
	{value: "Difensore", label: "Difensori"},
	{value: "Centrocampista", label: "Centrocampisti"},
	{value: "Attaccante", label: "Attaccanti"},
];

export const matchTypesOptions = [
	{value: "Amichevole", label: "Amichevole"},
	{value: "Campionato", label: "Campionato"},
	{value: "Coppa", label: "Coppa"},
	{value: "Torneo", label: "Torneo"},
];

export const fieldsOptions = [
	{value: "3 Campanili", label: "3 Campanili"},
	{value: "G.  Mugnaini", label: "G.  Mugnaini"},
	{value: "I. Ferrando", label: "I. Ferrando"},
	{value: "R. Garrone", label: "R. Garrone"},
	{value: "Sciorba Stadium", label: "Sciorba Stadium"},
	{value: "Villa Gavotti", label: "Villa Gavotti"},
	{value: "Marcelline", label: "Marcelline"},
];

export const attendanceStatusOptions = [
	{label: "Presente", value: "Presente"},
	{label: "Assente", value: "Assente"},
	{label: "Infortunato", value: "Infortunato"},
];

export const playlistOptions = [
	{value: "Clip allenamento", label: "Clip allenamento"},
	{value: "Clip gara", label: "Clip gara"},
	{value: "Video tecnici", label: "Video tecnici"},
];

export const testTypes = [
	{
		label: "Spinte",
		value: "Spinte",
		params: [
			{value: "SJ", label: "Squat Jump", um: "cm"},
			{value: "CMJ", label: "CM Jump vincolo", um: "cm"},
			{value: "CMJL", label: "CM Jump libero", um: "cm"},
			{value: "CMJ dx", label: "CM Jump mono vincolo dx", um: "cm"},
			{value: "SJ sx", label: "CM Jump mono vincolo sn", um: "cm"},
			{value: "CMJL dx", label: "CM Jump mono libero dx", um: "cm"},
			{value: "SJL sx", label: "CM Jump mono libero sn", um: "cm"},
			{value: "SJ mono dx", label: "SJ mono libero dx", um: "cm"},
			{value: "SJ mono sx", label: "SJ mono libero sn", um: "cm"},
			{
				value: "Stiffness bl TC",
				label: "Stiffness braccia libere t.c.",
				um: "s",
			},
			{value: "Stiffness bl TV", label: "Stiffness braccia libere", um: "cm"},
			{value: "Stiffness TC", label: "Stiffness t.c.", um: "s"},
			{value: "Stiffness TV", label: "Stiffness", um: "cm"},
			{value: 'CMJ 15" TC', label: 'CMJ 15" t.c.', um: "s"},
			{value: 'CMJ 15" TV', label: 'CMJ 15"', um: "cm"},
		],
	},
	{
		label: "Corse",
		value: "Corse",
		params: [
			{value: "5 metri", label: "5 metri", um: "s"},
			{value: "10 metri", label: "10 metri", um: "s"},
			{value: "15 metri", label: "15 metri", um: "s"},
		],
	},
];

export const defaultReportOptions = [
	{label: "Molto", value: "0"},
	{label: "Abbastanza", value: "1"},
	{label: "Poco", value: "2"},
];
export const originReportOptions = [
	{label: "Italia", value: "0"},
	{label: "Estero", value: "1"},
];

export const childTypeOptions = [
	{label: "Introverso", value: "0"},
	{label: "Estroverso", value: "1"},
];

export const communicationTypeOptions = [
	{label: "Adeguata", value: "0"},
	{label: "Non adeguata", value: "1"},
];

export const bodyTypeOptions = [
	{label: "Buona", value: "0"},
	{label: "Sufficiente", value: "1"},
	{
		label: "Insufficiente",
		value: "2",
	},
];
export const aptitudeForRole = [
	{label: "Si", value: "0"},
	{label: "No", value: "1"},
];

export const valuationOption = [
	{label: "Idoneo", value: "0"},
	{label: "Non idoneo", value: "1"},
	{label: "Da seguire", value: "2"},
	{label: "Da prendere", value: "3"},
];

export const valuationAnalysisOption = [
	{label: "Da vedere live", value: "to_view_live"},
	{label: "Da vedere video", value: "to_view_video"},
	{label: "Da provare", value: "to_test"},
	{label: "Visto live", value: "viewed_live"},
	{label: "Visto video", value: "viewed_video"},
];

export const externalValuationOptions = [
	{label: "Idoneo", value: "0"},
	{label: "Non idoneo", value: "1"},
	{label: "Da rivedere", value: "2"},
	{label: "Non interessante", value: "3"},
];

export const dateFormat = "DD/MM/YYYY";

export const REQUIRED_FIELD = "Campo obbligatorio";

export const SUCCESSFUL_OPERATION = "Operazione avvenuta con successo!";
export const ERROR_OPERATION =
	"Spiacenti, si è verificato un problema. Riprova!";

export const valuationsTypes = [
	{value: "training", label: "Allenamento"},
	{value: "match", label: "Partita"},
];
// ESTERNI BASE GARA
export const baseExternMatchParameters = [
	{
		label: "Struttura apparente",
		area: "struttura",
		fieldKey: "bodyTypeText",
		fieldKeyRating: "bodyTypeRating",
	},
	{
		label: "Personalità, comunicazione",
		area: "personalità",
		fieldKey: "personalityText",
		fieldKeyRating: "personalityRating",
	},
	{
		label: "Riconoscimento degli spazi",
		area: "tattica",
		fieldKey: "positionsText",
		fieldKeyRating: "positionsRating",
	},
	{
		label: "Attitudini al ruolo",
		area: "tecnica",
		fieldKey: "attitudeToRoleText",
		fieldKeyRating: "attitudeToRoleRating",
	},
	{
		label: "Motricità e capacità coordinative",
		area: "condizionali",
		fieldKey: "motricityText",
		fieldKeyRating: "motricityRating",
	},
	{
		label: "Coinvolgimento nel gioco",
		area: "tecnica",
		fieldKey: "involvementInGameText",
		fieldKeyRating: "involvementInGameRating",
	},
	{
		label: "Velocità di pensiero",
		area: "personalità",
		fieldKey: "quickThinkingText",
		fieldKeyRating: "quickThinkingRating",
	},
	{
		label: "Velocità e capacità reattive",
		area: "condizionali",
		fieldKey: "reactiveCapabilitiesText",
		fieldKeyRating: "reactiveCapabilitiesRating",
	},
	{
		label: "Attitudini da giocatore di movimento",
		area: "tecnica",
		fieldKey: "attitudeAsOutfieldPlayerText",
		fieldKeyRating: "attitudeAsOutfieldPlayerRating",
	},
	{
		label: "Abilità nelle scelte",
		area: "tattica",
		fieldKey: "abilityToChoicesText",
		fieldKeyRating: "abilityToChoicesRating",
	},
];
// ESTERNI AGONISTICA GARA
export const proExternMatchParameters = [
	{
		label: "Struttura apparente",
		area: "struttura",
		fieldKey: "bodyTypeText",
		fieldKeyRating: "bodyTypeRating",
	},
	{
		label: "Personalità, leadership, comunicazione",
		area: "personalità",
		fieldKey: "personalityText",
		fieldKeyRating: "personalityRating",
	},
	{
		label: "Posizioni, senso tattico difensivo",
		area: "tattica",
		fieldKey: "positionsText",
		fieldKeyRating: "positionsRating",
	},
	{
		label: "Posture, abilità spostamenti",
		area: "tecnica",
		fieldKey: "posturesText",
		fieldKeyRating: "posturesRating",
	},
	{
		label: "Difesa della porta",
		area: "tecnica",
		fieldKey: "goalDefenceText",
		fieldKeyRating: "goalDefenceRating",
	},
	{
		label: "Difesa dello spazio",
		area: "tattica",
		fieldKey: "spaceDefenceText",
		fieldKeyRating: "spaceDefenceRating",
	},
	{
		label: "Tecnica podalica, sostegni, visione di gioco",
		area: "tecnica",
		fieldKey: "podalTechniqueText",
		fieldKeyRating: "podalTechniqueRating",
	},
	{
		label: "Contrattacchi, ripartenze",
		area: "tattica",
		fieldKey: "restartsText",
		fieldKeyRating: "restartsRating",
	},
	{
		label: "Forza",
		area: "condizionali",
		fieldKey: "strengthText",
		fieldKeyRating: "strengthRating",
	},
	{
		label: "Velocità e capacità reattive",
		area: "condizionali",
		fieldKey: "reactiveCapabilitiesText",
		fieldKeyRating: "reactiveCapabilitiesRating",
	},
];

// ESTERNI BASE ALLENAMENTO
export const baseExternTrainingParameters = [
	{
		label: "Abilità nella risoluzione delle problematiche",
		area: "tattica",
		fieldKey: "learningAbilityText",
		fieldKeyRating: "learningAbilityRating",
	},
	{
		label: "Coraggio, personalità, predisposizione al lavoro",
		area: "personalità",
		fieldKey: "personalityText",
		fieldKeyRating: "personalityRating",
	},
	{
		label: "Riconoscimento degli spazi",
		area: "tattica",
		fieldKey: "positionsText",
		fieldKeyRating: "positionsRating",
	},
	{
		label: "Attitudini al ruolo",
		area: "tecnica",
		fieldKey: "attitudeToRoleText",
		fieldKeyRating: "attitudeToRoleRating",
	},
	{
		label: "Motricità e capacità coordinative",
		area: "condizionali",
		fieldKey: "motricityText",
		fieldKeyRating: "motricityRating",
	},
	{
		label: "Coinvolgimento nel gioco",
		area: "tecnica",
		fieldKey: "involvementInGameText",
		fieldKeyRating: "involvementInGameRating",
	},
	{
		label: "Competitività",
		area: "personalità",
		fieldKey: "competitivenessText",
		fieldKeyRating: "competitivenessRating",
	},
	{
		label: "Reazione all'errore",
		area: "personalità",
		fieldKey: "reactionToErrorText",
		fieldKeyRating: "reactionToErrorRating",
	},
	{
		label: "Velocità di pensiero",
		area: "personalità",
		fieldKey: "quickThinkingText",
		fieldKeyRating: "quickThinkingRating",
	},
	{
		label: "Velocità dei movimenti",
		area: "condizionali",
		fieldKey: "reactiveCapabilitiesText",
		fieldKeyRating: "reactiveCapabilitiesRating",
	},
	{
		fieldKey: "inclusionIntoTeamText",
		label: "Inserimento nel contesto squadra",
		fieldKeyRating: "inclusionIntoTeamRating",
		area: "personalità",
	},
	{
		label: "Attitudini da giocatore di movimento",
		area: "tecnica",
		fieldKey: "attitudeAsOutfieldPlayerText",
		fieldKeyRating: "attitudeAsOutfieldPlayerRating",
	},
];
// ESTERNI AGONISTICA ALLENAMENTO
export const proExternTrainingParameters = [
	{
		label: "Capacità di apprendimento e messa in pratica",
		area: "tattica",
		fieldKey: "learningAbilityText",
		fieldKeyRating: "learningAbilityRating",
	},
	{
		label: "Personalità, leadership, predisposizione al lavoro",
		area: "personalità",
		fieldKey: "personalityText",
		fieldKeyRating: "personalityRating",
	},
	{
		label: "Posizioni, senso tattico difensivo",
		area: "tattica",
		fieldKey: "positionsText",
		fieldKeyRating: "positionsRating",
	},
	{
		label: "Posture, abilità spostamenti",
		area: "tecnica",
		fieldKey: "posturesText",
		fieldKeyRating: "posturesRating",
	},
	{
		label: "Difesa della porta",
		area: "tecnica",
		fieldKey: "goalDefenceText",
		fieldKeyRating: "goalDefenceRating",
	},
	{
		label: "Difesa dello spazio",
		area: "tattica",
		fieldKey: "spaceDefenceText",
		fieldKeyRating: "spaceDefenceRating",
	},
	{
		label: "Tecnica podalica, sostegni, visione di gioco",
		area: "tecnica",
		fieldKey: "podalTechniqueText",
		fieldKeyRating: "podalTechniqueRating",
	},
	{
		label: "Contrattacchi, ripartenze",
		area: "tattica",
		fieldKey: "restartsText",
		fieldKeyRating: "restartsRating",
	},
	{
		label: "Forza",
		area: "condizionali",
		fieldKey: "strengthText",
		fieldKeyRating: "strengthRating",
	},
	{
		label: "Velocità e capacità reattive",
		area: "condizionali",
		fieldKey: "reactiveCapabilitiesText",
		fieldKeyRating: "reactiveCapabilitiesRating",
	},
	{
		fieldKey: "inclusionIntoTeamText",
		label: "Inserimento nel contesto squadra",
		fieldKeyRating: "inclusionIntoTeamRating",
		area: "personalità",
	},
	{
		fieldKey: "testResultText",
		label: "Risultati test effettuati",
		fieldKeyRating: "testResultTextRating",
		area: "capacità condizionali",
	},
];

// INTERNI
export const initialValuationParameters = [
	{
		fieldKey: "learningAbilityText",
		label: "Apprendimento e abilità risoluzione situazionale",
		fieldKeyRating: "learningAbilityRating",
		area: "tattica",
	},
	{
		fieldKey: "personalityText",
		label: "Personalità, leadership, predisposizione al lavoro",
		fieldKeyRating: "personalityRating",
		area: "personalità",
	},
	{
		fieldKey: "positionsText",
		label: "Posizioni, senso tattico difensivo",
		fieldKeyRating: "positionsRating",
		area: "tattica",
	},
	{
		fieldKey: "restartsText",
		label: "Contrattacchi, ripartenze",
		fieldKeyRating: "restartsRating",
		area: "tecnica",
	},
	{
		fieldKey: "goalDefenceText",
		label: "Difesa della porta",
		fieldKeyRating: "goalDefenceRating",
		area: "tecnica",
	},
	{
		fieldKey: "posturesText",
		label: "Postura, abilità spostamenti",
		fieldKeyRating: "posturesRating",
		area: "tattica",
	},
	{
		fieldKey: "podalTechniqueText",
		label: "Tecnica podalica, sostegni, visione di gioco",
		fieldKeyRating: "podalTechniqueRating",
		area: "tecnica",
	},
	{
		label: "Difesa dello spazio",
		area: "tattica",
		fieldKey: "spaceDefenceText",
		fieldKeyRating: "spaceDefenceRating",
	},
	{
		fieldKey: "strengthText",
		label: "Forza",
		fieldKeyRating: "strengthRating",
		area: "capacità condizionali",
	},
	{
		fieldKey: "reactiveCapabilitiesText",
		label: "Velocità di pensiero e capacità reattive",
		fieldKeyRating: "reactiveCapabilitiesRating",
		area: "tattica",
	},
	{
		fieldKey: "inclusionIntoTeamText",
		label: "Inserimento nel contesto squadra",
		fieldKeyRating: "inclusionIntoTeamRating",
		area: "personalità",
	},
	{
		fieldKey: "testResultText",
		label: "Risultati test effettuati",
		fieldKeyRating: "testResultTextRating",
		area: "capacità condizionali",
	},
];

export const initialBaseValuationParameters = [
	{
		fieldKey: "learningAbilityText",
		label: "Abilità nella risoluzione delle problematiche",
		fieldKeyRating: "learningAbilityRating",
		area: "tattica",
	},
	{
		fieldKey: "personalityText",
		label: "Coraggio, personalità, capacità di attenzione",
		fieldKeyRating: "personalityRating",
		area: "personalità",
	},
	{
		fieldKey: "positionsText",
		label: "Attitudine alla copertura degli spazi",
		fieldKeyRating: "positionsRating",
		area: "tattica",
	},
	{
		fieldKey: "attitudeToRoleText",
		label: "Attitudine al ruolo",
		fieldKeyRating: "attitudeToRoleRating",
		area: "tecnica",
	},
	{
		fieldKey: "motricityText",
		label: "Motricità",
		fieldKeyRating: "motricityRating",
		area: "condizionali",
	},
	{
		fieldKey: "involvementInGameText",
		label: "Coinvolgimento nel gioco",
		fieldKeyRating: "involvementInGameRating",
		area: "tecnica",
	},
	{
		fieldKey: "competitivenessText",
		label: "Competitività",
		fieldKeyRating: "competitivenessRating",
		area: "personalità",
	},
	{
		fieldKey: "reactionToErrorText",
		label: "Reazione all'errore",
		fieldKeyRating: "reactionToErrorRating",
		area: "personalità",
	},
	{
		fieldKey: "reactiveCapabilitiesText",
		label: "Velocità di pensiero",
		fieldKeyRating: "reactiveCapabilitiesRating",
		area: "tattica",
	},
	{
		fieldKey: "posturesText",
		label: "Velocità movimenti",
		fieldKeyRating: "posturesRating",
		area: "condizionali",
	},
	{
		fieldKey: "inclusionIntoTeamText",
		label: "Inserimento nel contesto squadra",
		fieldKeyRating: "inclusionIntoTeamRating",
		area: "personalità",
	},
	{
		fieldKey: "attitudeAsOutfieldPlayerText",
		label: "Attitudine da giocatore di movimento",
		fieldKeyRating: "attitudeAsOutfieldPlayerRating",
		area: "tecnica",
	},
];

export const entitySaveOptions = [
	{value: "new", label: "Inserisci nuovo elemento"},
	{value: "select", label: "Seleziona esistente"},
];

export const activityTypesOptions = [
	{value: "base", label: "Attività di base"},
	{value: "competitive", label: "Attività agonistica"},
];
