import * as React from "react";

import "./index.scss";

const gpsParams = [
	{value: "totTime", label: "Tempo"},
	{value: "totalDistance", label: "Dist tot"},
	{value: "distanceMinutes", label: "Dist / min"},
	{value: "distance16kmh", label: "Dist > 16 km/h"},
	{value: "edPercent", label: "% ED"},
	{value: "nAccelerations3ms", label: "N Acc > 3 m/s2"},
	{value: "nDecelerations3ms", label: "N Dec < -3 m/s2"},
	{value: "trPass", label: "Tr pass < 5 W/Kg"},
	{value: "distance2025kms", label: "Dist 20-25 km/h"},
	{value: "amax", label: "Amax"},
	{value: "imbalance", label: "Imbalance"},
	{value: "jumps", label: "Jumps"},
	{value: "diveRx", label: "Dives Rx"},
	{value: "diveLx", label: "Dives Lx"},
];

export default function GPSCard(props) {
	const {element} = props;

	const mainText = element.player
		? element.player?.firstName + " " + element.player?.lastName
		: element.match?.homeTeam +
			" - " +
			element.match?.awayTeam +
			" (" +
			element.fraction +
			"T)";

	return (
		<div className="gps-card-container">
			<div className="main-card-info">
				{element.total ? "Totale" : mainText}
			</div>
			<div className="card-params-container">
				{gpsParams.map((gp, k) => {
					return (
						<div className="card-param-container" key={k}>
							<div className="param-label">{gp.label}</div>
							<div className="param-value">{element[gp.value]}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
