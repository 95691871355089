import * as React from "react";
import {useSelector} from "react-redux";
import moment from "moment";

import {dateFormat} from "@/utils/constants";

import "./index.scss";

export default function MatchCard(props) {
	const {element, type, matchKey} = props;

	const userRole = useSelector((state) => state?.user?.roleName);

	let matchTypeIcon = "";
	switch (element.matchType) {
		case "Torneo":
		case "Coppa":
			matchTypeIcon = require("@/assets/images/coach/trophy.png");
			break;
		case "Amichevole":
			matchTypeIcon = require("@/assets/images/coach/match.png");
			break;
		case "Campionato":
			matchTypeIcon = require("@/assets/images/coach/league.png");
			break;
		default:
			break;
	}

	const hasRPE = parseInt(element?.playersCategory?.split(" ")?.[1]) > 13;

	return (
		<div className="match-card-container">
			<div className="d-flex flex-column">
				<div className="match-card-header">
					<div className="top-left">
						{moment(element.startDate || element.matchDate).format(
							dateFormat + " HH:mm",
						)}
					</div>
					<div className="top-right">{element.playersCategory}</div>
				</div>
				{type === "match_calendar" ? (
					<div className="top-center">
						<div className="match-counter">
							<div className="counter">{element.round || "---"}</div>
						</div>
					</div>
				) : null}
			</div>
			<div className="match-card-info">
				<div className="info-title">
					{(element.homeTeam || "---") + " - " + (element.awayTeam || "---")}
				</div>
				{type === "match_scout" ? (
					<div className="info-subtitle">
						{element.homeTeamGoals || "---"} - {element.awayTeamGoals || "---"}
					</div>
				) : (
					<div className="info-subtitle">
						{element.results || element.result || "---"}
					</div>
				)}
			</div>
			{type !== "match_scout" ? (
				<>
					<div className="separator"></div>
					<div className="match-card-preview">
						<div className="match-type">
							<img src={matchTypeIcon} alt="" />
							<div className="type-info">{element.matchType}</div>
						</div>
						{type === "match_player" ? (
							<div className="match-stats">
								<div className="single-stat">
									<img src={require("@/assets/images/coach/time.png")} alt="" />
									<div>
										{element.minutesPlayed !== null &&
										element.minutesPlayed !== undefined
											? element.minutesPlayed + "/" + element.matchMinutes
											: "---"}
									</div>
								</div>
								<div className="single-stat">
									<img
										src={require("@/assets/images/coach/football.png")}
										alt=""
									/>
									<div>
										{element.goalsConceded !== null &&
										element.goalsConceded !== undefined
											? element.goalsConceded + "/" + element.totalGoalsConceded
											: "---"}
									</div>
								</div>
							</div>
						) : null}
						{type !== "match_player" ? (
							<>
								{userRole !== "Player" ? (
									<div className="match-stats">
										<div className="single-stat">
											<img
												src={require("@/assets/images/manager/player.png")}
												alt=""
											/>
											<div>{element.matchConvocations?.length || 0}</div>
										</div>
										{hasRPE && (
											<div className="single-stat">
												<img
													src={require("@/assets/images/coach/rpe.png")}
													alt=""
												/>
												<div>{element.matchRpe?.length || 0}</div>
											</div>
										)}
										<div className="single-stat">
											<img
												src={require("@/assets/images/coach/analysis.png")}
												alt=""
											/>
											<div>{element.matchEvaluations?.length || 0}</div>
										</div>
									</div>
								) : hasRPE ? (
									<div className="match-stats">
										<div className="single-stat">
											<div>RPE</div>
											<div
												className={
													"value" +
													(element.matchRpe?.length ? " inserted" : " empty")
												}
											></div>
										</div>
									</div>
								) : (
									<></>
								)}
							</>
						) : null}
					</div>
				</>
			) : (
				<>
					<div className="separator"></div>
					<div className="match-card-preview">
						<div className="match-type">
							<div className="type-info">{element.fieldName}</div>
						</div>
						{type !== "match_player" ? (
							<>
								<div className="match-stats">
									<div className="single-stat">
										<div>{element.scoutNames || "---"}</div>
									</div>
								</div>
							</>
						) : null}
					</div>
				</>
			)}
		</div>
	);
}
