import api from "./configuration";
import apiUrl from "./url";

const USE_AUTH_INTERCEPTOR = true;

export const PlayerAPI = {
	list: async (filtersData) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Players",
			params: filtersData,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getById: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Players/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	create: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Players",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	update: async (id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Players/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	delete: async (id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Players/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getPlayerNotes: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Players/" + id + "/MatchesNotes",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getPlayerMovements: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/PlayersMovementsByPlayer/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createPlayerMovement: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/PlayersMovements",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updatePlayerMovement: async (id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/PlayersMovements/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deletePlayerMovement: async (id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/PlayersMovements/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createGrowth: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Players/growth",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updateGrowth: async (playerId, id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Players/" + playerId + "/growth/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteGrowth: async (playerId, id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Players/" + playerId + "/growth/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createContact: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Players/contacts",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updateContact: async (playerId, id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Players/" + playerId + "/contacts/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteContact: async (playerId, id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Players/" + playerId + "/contacts/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createCV: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Players/cv",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updateCV: async (playerId, id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Players/" + playerId + "/cv/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteCV: async (playerId, id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Players/" + playerId + "/cv/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getNotes: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Players/" + id + "/notes",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createNote: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Players/notes",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updateNote: async (playerId, id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Players/" + playerId + "/notes/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteNote: async (playerId, id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Players/" + playerId + "/notes/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	listTQR: async (filtersData) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Players/tqr",
			params: filtersData,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	listRPE: async (filtersData) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Players/Rpe",
			params: filtersData,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	listVAS: async (filtersData) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Players/Vas",
			params: filtersData,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	playerListTQR: async (playerId, filtersData) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Players/" + playerId + "/tqr",
			params: filtersData,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	playerListVAS: async (playerId, filtersData) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Players/" + playerId + "/Vas",
			params: filtersData,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getByTQRId: async (playerId, tqrId) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Players/" + playerId + "/tqr/" + tqrId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getByVASId: async (playerId, tqrId) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Players/" + playerId + "/Vas/" + tqrId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createTQR: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Players/tqr",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updateTQR: async (playerId, tqrId, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Players/" + playerId + "/tqr/" + tqrId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteTQR: async (playerId, tqrId) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Players/" + playerId + "/tqr/" + tqrId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createVAS: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Players/Vas",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updateVAS: async (playerId, tqrId, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Players/" + playerId + "/Vas/" + tqrId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteVAS: async (playerId, tqrId) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Players/" + playerId + "/Vas/" + tqrId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getQuickStats: async (playerId) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/GoalkeeperMatchesQuickStats/" + playerId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getDetailedQuickStats: async (playerId) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/GoalkeeperMatchesDetailedQuickStats/" + playerId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
};
