import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Loading from "@/components/Loading";
import VLFSelectNew from "@/components/Input/SelectNew";
import VLFTextField from "@/components/Input/TextField";
import Button from "@mui/material/Button";
import CardsContainer from "@/components/CardsContainer";
import CardsTable from "@/components/CardsTable";
import CardsList from "@/components/CardsList";
import EmptyData from "@/components/EmptyData";

import {GpsAPI} from "@/api/gps";

import ControlPointIcon from "@mui/icons-material/ControlPoint";

import {ERROR_OPERATION, SUCCESSFUL_OPERATION} from "@/utils/constants";
import {usePlayers} from "@/utils/hooks";

import "./index.scss";

export default function GPSContainer(props) {
	const {
		handleNotification,
		gpsData,
		matchId,
		playerId,
		callback,
		handleDelete,
	} = props;

	const navigate = useNavigate();

	const {players} = usePlayers({
		setLoading: false,
		handleNotification: undefined,
		onlyInternal: true,
	});

	const [loading, setLoading] = useState(false);
	const [matchGpsMode, setMatchGpsMode] = useState("view");
	const [editingMatchGpsData, setEditingMatchGpsData] = useState([]);

	const handleGpsData = async () => {
		setLoading(true);

		let promises = [];
		editingMatchGpsData.forEach((g) => {
			const body = {
				...g,
				matchId: matchId,
				playerId: g.player?.value,
			};

			promises.push(
				g.id ? GpsAPI.update(matchId, g.id, body) : GpsAPI.create(body),
			);
		});

		try {
			await Promise.all(promises);
			handleAction();
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const handleAction = () => {
		callback();
		setMatchGpsMode("view");
		setEditingMatchGpsData([]);
	};

	const matchGpsColumns = [];
	if (matchId) {
		matchGpsColumns.push({
			label: "Giocatore",
			key: "player",
			formField: matchGpsMode !== "view",
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFSelectNew
								options={players.map((p) => {
									return {
										value: p.id,
										label: p.firstName + " " + p.lastName,
									};
								})}
								value={item.player || null}
								onChange={(value) => {
									onChange(value);
								}}
								tableField
							/>
						)
					: undefined,
			customRender:
				matchGpsMode === "view"
					? (item) => {
							return item?.player?.firstName + " " + item?.player?.lastName;
						}
					: undefined,
		});
	}

	if (playerId) {
		matchGpsColumns.push({
			label: "Tempo di gioco",
			key: "fraction",
			customRender: (item) => {
				return item.fraction ? item.fraction + "T" : "";
			},
		});
	}

	matchGpsColumns.push(
		{
			label: "Tempo",
			medium: true,
			key: "totTime",
			addible: true,
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFTextField
								value={item.totTime || ""}
								onChange={(e) => {
									const value = e.target.value;
									onChange(value);
								}}
								size="small"
							/>
						)
					: undefined,
		},
		{
			label: "Dist tot",
			medium: true,
			key: "totalDistance",
			addible: true,
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFTextField
								value={item.totalDistance || null}
								onChange={(e) => {
									const value = e.target.value;
									onChange(value);
								}}
								type="number"
								size="small"
							/>
						)
					: undefined,
		},
		{
			label: "Dist / min",
			medium: true,
			key: "distanceMinutes",
			addible: true,
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFTextField
								value={item.distanceMinutes || null}
								onChange={(e) => {
									const value = e.target.value;
									onChange(value);
								}}
								type="number"
								size="small"
							/>
						)
					: undefined,
		},
		{
			label: "Dist > 16 km/h",
			medium: true,
			key: "distance16kmh",
			addible: true,
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFTextField
								value={item.distance16kmh || null}
								onChange={(e) => {
									const value = e.target.value;
									onChange(value);
								}}
								type="number"
								size="small"
							/>
						)
					: undefined,
		},
		{
			label: "% ED",
			medium: true,
			key: "edPercent",
			addible: true,
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFTextField
								value={item.edPercent || null}
								onChange={(e) => {
									const value = e.target.value;
									onChange(value);
								}}
								type="number"
								size="small"
							/>
						)
					: undefined,
		},
		{
			label: "N Acc > 3 m/s2",
			medium: true,
			key: "nAccelerations3ms",
			addible: true,
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFTextField
								value={item.nAccelerations3ms || null}
								onChange={(e) => {
									const value = e.target.value;
									onChange(value);
								}}
								type="number"
								size="small"
							/>
						)
					: undefined,
		},
		{
			label: "N Dec < -3 m/s2",
			medium: true,
			key: "nDecelerations3ms",
			addible: true,
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFTextField
								value={item.nDecelerations3ms || null}
								onChange={(e) => {
									const value = e.target.value;
									onChange(value);
								}}
								type="number"
								size="small"
							/>
						)
					: undefined,
		},
		{
			label: "Tr pass < 5 W/Kg",
			medium: true,
			key: "trPass",
			datesAddible: true,
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFTextField
								value={item.trPass || ""}
								onChange={(e) => {
									const value = e.target.value;
									onChange(value);
								}}
								size="small"
							/>
						)
					: undefined,
		},
		{
			label: "Dist 20-25 km/h",
			medium: true,
			key: "distance2025kms",
			addible: true,
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFTextField
								value={item.distance2025kms || null}
								onChange={(e) => {
									const value = e.target.value;
									onChange(value);
								}}
								type="number"
								size="small"
							/>
						)
					: undefined,
		},
		{
			label: "Amax",
			medium: true,
			key: "amax",
			addible: true,
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFTextField
								value={item.amax || null}
								onChange={(e) => {
									const value = e.target.value;
									onChange(value);
								}}
								type="number"
								size="small"
							/>
						)
					: undefined,
		},
		{
			label: "Imbalance",
			medium: true,
			key: "imbalance",
			addible: true,
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFTextField
								value={item.imbalance || null}
								onChange={(e) => {
									const value = e.target.value;
									onChange(value);
								}}
								type="number"
								size="small"
							/>
						)
					: undefined,
		},
		{
			label: "Jumps",
			medium: true,
			key: "jumps",
			addible: true,
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFTextField
								value={item.jumps || null}
								onChange={(e) => {
									const value = e.target.value;
									onChange(value);
								}}
								type="number"
								size="small"
							/>
						)
					: undefined,
		},
		{
			label: "Dives Rx",
			medium: true,
			key: "diveRx",
			addible: true,
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFTextField
								value={item.diveRx || null}
								onChange={(e) => {
									const value = e.target.value;
									onChange(value);
								}}
								type="number"
								size="small"
							/>
						)
					: undefined,
		},
		{
			label: "Dives Lx",
			medium: true,
			key: "diveLx",
			addible: true,
			editRender:
				matchGpsMode !== "view"
					? ({item, onChange}) => (
							<VLFTextField
								value={item.diveLx || null}
								onChange={(e) => {
									const value = e.target.value;
									onChange(value);
								}}
								type="number"
								size="small"
							/>
						)
					: undefined,
		},
	);

	const currentGpsData =
		matchGpsMode === "view"
			? Array.from(gpsData)
			: Array.from(editingMatchGpsData);
	const gpsData1 = Array.from(currentGpsData?.filter((g) => g.fraction === 1));
	const gpsData2 = Array.from(currentGpsData?.filter((g) => g.fraction === 2));

	const listConfiguration1 = {
		data: gpsData1,
	};
	const listConfiguration2 = {
		data: gpsData2,
	};

	const fractionsConfiguration = [
		{
			title: "1° tempo",
			data: gpsData1,
			listConfiguration: listConfiguration1,
			fraction: 1,
		},
		{
			title: "2° tempo",
			data: gpsData2,
			listConfiguration: listConfiguration2,
			fraction: 2,
		},
	];

	const calculateSum = (data) => {
		// Crea un nuovo oggetto in cui aggiungere i risultati
		const result = {};

		// Funzione per convertire "HH:mm:ss" in secondi totali
		const timeToSeconds = (time) => {
			const [hours, minutes, seconds] = time.split(":").map(Number);
			return hours * 3600 + minutes * 60 + seconds;
		};

		// Funzione per convertire secondi totali in "HH:mm:ss"
		const secondsToTime = (totalSeconds) => {
			const hours = Math.floor(totalSeconds / 3600);
			const minutes = Math.floor((totalSeconds % 3600) / 60);
			const seconds = totalSeconds % 60;
			return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
		};

		// Itera su ogni chiave (matchId)
		for (const matchId in data) {
			if (Array.isArray(data[matchId])) {
				// Ottieni l'array associato al matchId
				const matchArray = data[matchId];

				// Calcola le somme
				const summedObject = matchArray.reduce(
					(acc, obj) => {
						for (const key in obj) {
							const currentColumn = matchGpsColumns?.filter(
								(c) => c.key === key,
							)?.[0];
							if (
								(currentColumn === "id" || currentColumn?.addible) &&
								obj[key]
							) {
								acc[key] =
									(acc[key] || 0) +
									parseFloat(obj[key]?.toString().replace(",", "."));
								acc[key] = parseFloat(acc[key].toFixed(2));
							} else if (currentColumn?.datesAddible) {
								// Somma di date (HH:mm:ss)
								const currentValueInSeconds = timeToSeconds(
									obj[key] || "00:00:00",
								);
								const accValueInSeconds = timeToSeconds(acc[key] || "00:00:00");
								const sumSeconds = currentValueInSeconds + accValueInSeconds;
								acc[key] = currentValueInSeconds
									? secondsToTime(sumSeconds)
									: ""; // Converti in HH:mm:ss
							}
						}
						return acc;
					},
					{
						total: true,
					},
				);

				// Aggiungi il nuovo oggetto nell'array
				result[matchId] = [...matchArray, summedObject];
			}
		}

		return result;
	};

	const calculatedGpsData = calculateSum(gpsData);

	return (
		<>
			<Loading visible={loading} />
			{matchId && matchGpsMode === "view" && (
				<div className="gps-buttons align-items-center justify-content-end mt-2">
					<Button
						variant="contained"
						onClick={() => {
							let data = Array.from(gpsData);
							data = data.map((d) => {
								if (d.player) {
									d.player.value = d.player.id;
									d.player.label = d.player.firstName + " " + d.player.lastName;
								}
								return d;
							});
							setEditingMatchGpsData(data);
							setMatchGpsMode("edit");
						}}
						size="small"
					>
						<div className="d-flex align-items-center">
							<div>Modifica</div>
						</div>
					</Button>
				</div>
			)}
			<div className="d-flex flex-column gap-4">
				{matchId &&
					fractionsConfiguration.map((fc, k) => {
						return (
							<div key={k}>
								<CardsContainer
									tableComponent={
										<CardsTable
											title={fc.title}
											size="sm"
											fullHeight={false}
											middleHeight
											fullWidth
											configurationColumns={matchGpsColumns}
											data={fc.data}
											actions={
												matchGpsMode !== "view"
													? [
															{
																icon: <ControlPointIcon />,
																onClick: () => {
																	const newData = [...gpsData1, ...gpsData2];
																	newData.push({
																		mode: "new",
																		fraction: fc.fraction,
																		player: null,
																	});
																	setEditingMatchGpsData(newData);
																},
															},
														]
													: []
											}
											handleDataChange={(data) => {
												const collection =
													fc.fraction === 1
														? [...data, ...gpsData2]
														: [...data, ...gpsData1];
												setEditingMatchGpsData(Array.from(collection));
											}}
											handleDelete={
												matchGpsMode !== "view"
													? (item) => {
															if (!item.mode) {
																handleDelete(item);
															}
														}
													: undefined
											}
										/>
									}
									listComponent={
										<>
											<div style={{fontWeight: "bold"}} className="px-2">
												{fc.title}
											</div>
											<CardsList
												type="gps"
												listConfiguration={fc.listConfiguration}
											/>
										</>
									}
								/>
							</div>
						);
					})}
				{playerId &&
					Object.keys(calculatedGpsData)?.length &&
					Object.keys(calculatedGpsData)
						.sort((a, b) => {
							const dateA = new Date(
								calculatedGpsData[a]?.[0]?.match?.startDate || 0,
							);
							const dateB = new Date(
								calculatedGpsData[b]?.[0]?.match?.startDate || 0,
							);
							return dateB - dateA;
						})
						.map((m, k) => {
							const currentMatch = calculatedGpsData[m]?.[0]?.match || {};
							const title =
								(currentMatch?.homeTeam || "---") +
								" - " +
								(currentMatch?.awayTeam || "---") +
								" (" +
								moment(currentMatch?.startDate).format("DD/MM/YYYY") +
								")";
							return (
								<div key={k} style={{borderBottom: "1px solid #002160"}}>
									<div className="d-flex align-items-center justify-content-end w-100">
										<Button
											variant="contained"
											onClick={() => {
												navigate("/matches/" + m);
											}}
											size="small"
										>
											<div className="d-flex align-items-center">
												<div>Vai alla partita</div>
											</div>
										</Button>
									</div>
									<CardsContainer
										tableComponent={
											<CardsTable
												title={title}
												size="sm"
												fullHeight={false}
												middleHeight
												// fullWidth
												configurationColumns={matchGpsColumns}
												data={calculatedGpsData[m]}
												defaultSortedField="fraction"
												rowClassName={(row) => {
													return row.total ? "total" : "";
												}}
												// actions={
												// 	matchGpsMode !== "view"
												// 		? [
												// 				{
												// 					icon: <ControlPointIcon />,
												// 					onClick: () => {
												// 						const newData = [...gpsData1, ...gpsData2];
												// 						newData.push({
												// 							mode: "new",
												// 							fraction: fc.fraction,
												// 							player: null,
												// 						});
												// 						setEditingMatchGpsData(newData);
												// 					},
												// 				},
												// 			]
												// 		: []
												// }
												// handleDataChange={(data) => {
												// 	const collection =
												// 		fc.fraction === 1
												// 			? [...data, ...gpsData2]
												// 			: [...data, ...gpsData1];
												// 	setEditingMatchGpsData(Array.from(collection));
												// }}
												// handleDelete={
												// 	matchGpsMode !== "view"
												// 		? (item) => {
												// 				if (!item.mode) {
												// 					handleDelete(item);
												// 				}
												// 			}
												// 		: undefined
												// }
											/>
										}
										listComponent={
											<>
												{/* <div style={{fontWeight: "bold"}} className="px-2">
													{title}
												</div> */}
												<CardsList
													type="gps"
													listConfiguration={{
														data: calculatedGpsData[m],
														customClassName: (element) =>
															element.total ? "total" : "",
													}}
													defaultSortedField="fraction"
												/>
											</>
										}
									/>
								</div>
							);
						})}
			</div>
			{matchGpsMode !== "view" && (
				<div className="gps-buttons align-items-center justify-content-end mt-2 gap-2">
					<Button
						color="error"
						variant="outlined"
						onClick={() => {
							handleAction();
						}}
						size="small"
					>
						<div className="d-flex align-items-center">
							<div>Chiudi</div>
						</div>
					</Button>
					<Button
						color="success"
						variant="outlined"
						onClick={handleGpsData}
						size="small"
						disabled={
							!editingMatchGpsData?.length ||
							editingMatchGpsData?.filter((g) => !g.player)?.length
								? true
								: false
						}
					>
						<div className="d-flex align-items-center">
							<div>Salva</div>
						</div>
					</Button>
				</div>
			)}
		</>
	);
}
